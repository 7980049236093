import React from "react";
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import getReferenceList from '../../../services/event-references-helpers';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/spellchecker/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../../stylesheets/tinymce/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';

class CustomCreatableSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputMinWidth: 150,
            inputWidth: 0,
            selectedActionType: ''
        };
        this.inputSizer = React.createRef();
    }

    componentDidMount() {
        const newWidth = this.inputSizer.current ? this.inputSizer.current.scrollWidth + 45 : 0
        this.setState({
            inputWidth: newWidth
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const newWidth = this.inputSizer.current ? this.inputSizer.current.scrollWidth + 45 : 0
            this.setState({
                inputWidth: newWidth
            });
        }
    }
    render() {
        const wrapperStyle = {
            display: 'inline-block',
            position: 'relative'
        };
        const inputStyle = {
            minWidth: `${this.state.inputMinWidth}px`,
            width: `${this.state.inputWidth}px`,
            boxShadow: this.props.autoselected && !this.props.isNewEvent ? '0 0 7px 1px green' : '',
            borderRadius: this.props.autoselected && !this.props.isNewEvent ? '4px' : '',
            marginTop: this.props.autoselected && !this.props.isNewEvent ? '5px' : ''
        };
        const sizerStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            visibility: 'hidden',
            height: 0,
            overflow: 'scroll',
            whiteSpace: 'pre',
        };
        const inputValue = this.props.getOptionLabel ? this.props.getOptionLabel(this.props.value) :
            this.props.value ? this.props.value.label : '';
        return (
            <div style={wrapperStyle}>
                {this.props.autoselected && !this.props.isNewEvent && <label><small><em>This has been autoselected, but not saved.</em></small></label>}
                <div ref={this.inputSizer} style={sizerStyle}>{inputValue}</div>
                <div style={inputStyle}>
                    <CreatableSelect
                        options={this.props.options}
                        styles={selectStyle()}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        formatCreateLabel={input => input}
                        //This is so an option can only be created once an option has been selected
                        isValidNewOption={() => Boolean(this.props.value)}
                        {...this.props} />
                </div>
            </div>
        );
    }
}

class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputMinWidth: 150,
            inputWidth: 0
        };
        this.inputSizer = React.createRef();
    }

    componentDidMount() {
        const newWidth = this.inputSizer.current ? this.inputSizer.current.scrollWidth + 2 : 0
        this.setState({
            inputWidth: newWidth
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const newWidth = this.inputSizer.current ? this.inputSizer.current.scrollWidth + 2 : 0
            this.setState({
                inputWidth: newWidth
            });
        }
    }

    render() {
        const wrapperStyle = {
            display: 'inline-block',
            position: 'relative'
        };
        const inputStyle = {
            minWidth: `${this.state.inputMinWidth}px`,
            width: `${this.state.inputWidth}px`,
            maxWidth: '70vw'
        };
        const sizerStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            visibility: 'hidden',
            height: 0,
            overflow: 'scroll',
            whiteSpace: 'pre',
        };
        return (<div style={wrapperStyle}>
            <div ref={this.inputSizer} style={sizerStyle}>{this.props.value}</div>
            {this.props.textListLength > 0
                ? <input
                    {...this.props}
                    style={inputStyle}
                />
                : <React.Fragment>
                    <textarea
                        {...this.props}
                        style={inputStyle}
                    />
                </React.Fragment>
            }

        </div>)
    }
}

//This class is used to take the ActivityReferences and turn them into a form
class InputType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleActionTypeChange = this.handleActionTypeChange.bind(this);
        this.addInput = this.addInput.bind(this);

        this.actionTypeDropdown = React.createRef();
    }

    handleChange(referenceIndex, value, autoselected) {
        const { entryIndex, activityIndex, references, billHistory } = this.props;
        //Send the index of the minute entry, the entire minute entries object (so we don't have to save the entire book),
        //the value of the text input, and the sequence to show which category to update
        const originalValue = JSON.parse(JSON.stringify(references[referenceIndex]));
        let reference = references[referenceIndex];
        switch (reference.ActionReferenceType) {
            case 'Member':
            case 'Committee':
            case 'Subcommittee':
                reference.ReferenceText = value.label;
                //If it is not created then __isNew__ will be false. Since it is not created then set the ID to whatever was selected
                //The Creatable does not allow  creating an option if an option has not been selected yet, so if the value is new then use the existing reference ID
                if (!value.__isNew__) {
                    reference.ReferenceID = value.value;
                }
                break;
            case 'LegislationText':
                reference.ReferenceText = value.Description || value.label;
                if (!value.__isNew__) {
                    reference.ReferenceID = value.value;
                } else {
                    reference.ReferenceText = value.label
                }
                reference.autoselected = autoselected;
                break;
            case 'Time':
                reference.ReferenceText = value.format('h:mm a');
                break;
            case 'Date and Time':
                reference.ReferenceText = value.format("MMMM d, yyyy h:mm a");
                break;
            case 'Text':
                reference.ReferenceText = value;
                break;
            case 'HTML':
                reference.ReferenceText = value;
                break;
            case 'LegislationEvent':
                reference.ReferenceID = value.LegislationEventID;
                reference.EventCode = value.EventCode;
                reference.IsPassed = value.IsPassed;
                break;
            case 'Legislation':
                reference.ReferenceText = value.label;
                reference.ReferenceID = value.value;
                break;
            case 'Communication':
                reference.ReferenceText = value.ReferenceNumber;
                reference.CommunicationDate = value.CommunicationDate;
                reference.CommunicationNumber = value.CommunicationNumber;
                if (!value.__isNew__) {
                    reference.ReferenceID = value.value;
                }
                reference.CommunicationSummaries = value.CommunicationSummaries
                break;
            default:
                reference.ReferenceText = value;
        }
        this.props.handleChange(entryIndex, activityIndex, referenceIndex, reference, !billHistory && originalValue);
    }

    handleActionTypeChange(val) {
        this.setState({
            selectedActionType: val
        })
    }

    addInput() {
        const { entryIndex, activityIndex, references } = this.props;
        const newInput = {
            ReferenceID: null,
            ReferenceText: '',
            ActionReferenceTypeID: this.state.selectedActionType.ActionReferenceTypeID,
            ActionReferenceType: this.state.selectedActionType.ActionReferenceType
        }
        this.props.handleChange(entryIndex, activityIndex, references.length, newInput);
        this.setState({
            selectedActionType: ''
        });
    }

    render() {
        const { entryIndex, activityIndex, references, actionTypes, style } = this.props;
        const divStyle = {
            display: 'inline-block',
            margin: '0px 5px 3px 5px',
            lineHeight: '0.8em'
        }

        const errorCheck = (referenceIndex) => {
            return (
                this.props.incompleteInputs && this.props.incompleteInputs.includes(referenceIndex) &&
                <span className="fail" style={{ fontSize: "12px" }}>You must enter a value.</span>
            )
        }

        return (
            <div style={{ ...style }}>
                {references.map((reference, referenceIndex) => {
                    switch (reference.ActionReferenceType) {
                        case 'Text':
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomInput
                                        value={reference.ReferenceText}
                                        onChange={(event) => this.handleChange(referenceIndex, event.target.value)}
                                        textListLength={this.props.textList.length}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'HTML':
                            return (
                                <div key={referenceIndex} style={{ ...divStyle, width: '100%' }}>
                                    <Editor
                                        value={reference.ReferenceText}
                                        init={{
                                            force_br_newlines: false,
                                            force_p_newlines: false,
                                            forced_root_block: '', //don't wrap votetally in minutes activities
                                            plugins: 'link paste save lists spellchecker preview searchreplace pagebreak code table',
                                            toolbar: 'undo redo | bold italic strikethrough | alignleft aligncenter alignright',
                                            skin: false,
                                            valid_children: '+body[style]',
                                            content_css: false,
                                            content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }',
                                        }}
                                        onEditorChange={content => this.handleChange(referenceIndex, content)}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Member':
                            let memberVal = null;
                            const member = this.props.memberList.find(member => member.MemberID === reference.ReferenceID);
                            if (member) {
                                memberVal = {
                                    label: reference.ReferenceText,
                                    value: reference.ReferenceID
                                };
                            }
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, this.props.memberList || []) : this.props.memberList || []}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                        value={memberVal}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Committee':
                            let committeeVal = null;
                            const committee = this.props.committeeList.find(member => member.CommitteeID === reference.ReferenceID);
                            if (committee) {
                                committeeVal = {
                                    label: reference.ReferenceText,
                                    value: reference.ReferenceID
                                };
                            }
                            let committeeList = this.props.committeeList ? this.props.committeeList.filter(c => !c.ParentCommitteeID) : [];
                            let eventChamber = this.props.newEvent.EventCode[0];
                            committeeList = committeeList.filter(x => x.ChamberCode == eventChamber)
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, committeeList) : committeeList}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                        value={committeeVal}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Subcommittee':
                            let subCommitteeVal = null;
                            const subCommittee = this.props.committeeList.find(member => member.CommitteeID === reference.ReferenceID);
                            if (subCommittee) {
                                subCommitteeVal = {
                                    label: reference.ReferenceText,
                                    value: reference.ReferenceID
                                };
                            }
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, this.props.committeeList ? this.props.committeeList.filter(c => c.ParentCommitteeID) : []) : this.props.committeeList ? this.props.committeeList.filter(c => c.ParentCommitteeID) : []}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                        value={subCommitteeVal}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'LegislationText':
                            const options = this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, this.props.textList || []) : this.props.textList || [];
                            let textVal;
                            //if we want to autoselect, do so only if the very first hierarchy/grouping has exactly one option; this is very likely the one they want to use
                            if (this.props.autoselect && !reference.ReferenceText && !reference.ReferenceID && options.length && options[0].options && options[0].options.length === 1) {
                                this.handleChange(referenceIndex, options[0].options[0], true);
                            }
                            textVal = {
                                label: reference.ReferenceText !== null ? reference.ReferenceText : "Select...",
                                value: reference.ReferenceID
                            }
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={options}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                        value={textVal}
                                        getOptionLabel={opt => {
                                            let newLabel = '';
                                            if (opt.IsActive !== undefined) {
                                                newLabel = `(${opt.IsActive ? "active" : "inactive"}) ${opt.label}`;
                                            } else {
                                                newLabel = opt.label;
                                            }
                                            newLabel += opt.LDNumber ? ` (${opt.LDNumber})` : '';
                                            return newLabel;
                                        }}
                                        autoselected={reference.autoselected}
                                        isNewEvent={this.props.isNewEvent}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Time':
                            const time = reference.ReferenceText ? moment(reference.ReferenceText, 'h:mm a').isValid() ? moment(reference.ReferenceText, 'h:mm a') : null : null;
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <DatePicker
                                        className='clock-date-picker'
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        dateFormat="h:mm a"
                                        timeCaption="Time"
                                        selected={time}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Date and Time':
                            const datetime = reference.ReferenceText ? moment(reference.ReferenceText).isValid() ? moment(reference.ReferenceText) : null : null;
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <DatePicker
                                        className='clock-date-picker'
                                        showTimeSelect
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm a"
                                        timeCaption="Time"
                                        selected={datetime}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'LegislationEvent':
                            return (
                                <div key={referenceIndex} style={{ marginLeft: "-5px", marginTop: "5px" }}>
                                    {this.props.newEvent && ((this.props.newEvent.ActionReferences && this.props.newEvent.ActionReferences[0] && this.props.newEvent.ActionReferences[0].ActionReferenceType === "LegislationEvent") || (this.props.newEvent.ActivityReferences && this.props.newEvent.ActivityReferences[0] && this.props.newEvent.ActivityReferences[0].ActionReferenceType === "LegislationEvent") || (this.props.newEvent.EventReferences && this.props.newEvent.EventReferences[0] && this.props.newEvent.EventReferences[0].ActionReferenceType === "LegislationEvent")) && this.props.eventList &&
                                        <div style={divStyle}>
                                            {this.props.eventList.length > 0 ?
                                                <CustomCreatableSelect
                                                    options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, this.props.eventList) : this.props.eventList}
                                                    value={reference.ReferenceID && this.props.eventList.find(evt => evt.LegislationEventID === reference.ReferenceID) ? this.props.eventList.find(evt => evt.LegislationEventID === reference.ReferenceID) : ""}
                                                    onChange={(event) => this.handleChange(referenceIndex, event)}
                                                    getOptionValue={o => o.LegislationEventID}
                                                    getOptionLabel={o => o.EventCode ? "(" + o.EventCode + ") " + o.Description : ""}
                                                />
                                                :
                                                <p>No eligible events.</p>
                                            }
                                        </div>
                                    }
                                    {errorCheck(referenceIndex)}
                                </div>
                            )
                        case 'Communication':
                            let communicationList = this.props.communicationList || [];
                            let communicationVal = null;
                            const communication = communicationList.find(comm => comm.CommunicationID === reference.ReferenceID);
                            if (communication) {
                                communicationVal = {
                                    label: reference.ReferenceText,
                                    value: reference.ReferenceID
                                };
                            }
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, communicationList) : communicationList}
                                        onChange={(communication) => this.handleChange(referenceIndex, communication)}
                                        value={communicationVal}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        case 'Legislation':
                            let legislationVal = null;
                            const legislation = this.props.legislationList ? this.props.legislationList.find(leg => leg.LegislationID === reference.ReferenceID) : null;
                            if (legislation) {
                                legislationVal = {
                                    label: reference.ReferenceText,
                                    value: reference.ReferenceID
                                };
                            }
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomCreatableSelect
                                        options={this.props.newEvent && this.props.newEvent.EventCode ? getReferenceList(this.props.newEvent.EventCode, this.props.legislationList || []) : this.props.legislationList || []}
                                        onChange={(event) => this.handleChange(referenceIndex, event)}
                                        value={legislationVal}
                                        placeholder={this.props.fetchingSessionLegislation ? 'Loading bills...' : 'Select...'}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                        default:
                            return (
                                <div key={referenceIndex} style={divStyle}>
                                    <CustomInput
                                        value={reference.ReferenceText}
                                        onChange={(event) => this.handleChange(referenceIndex, event.target.value)}
                                    />
                                    {errorCheck(referenceIndex)}
                                </div>
                            );
                    }
                })
                }
                {actionTypes && actionTypes.length > 0 && this.props.textList.length > 0 && (!this.props.newEvent?.ActionReferences?.length || this.props.newEvent.ActionReferences[0].ActionReferenceType !== "LegislationEvent") &&
                    <div style={divStyle}>
                        <label className="txt-greyed smaller-text" style={{ opacity: this.state.selectedActionType ? 1 : 0 }}>Press enter to add</label>
                        <Select
                            onKeyDown={(e) => {
                                //Enter can also be used to select an option. Make sure that the menu is closed before trying to add the input
                                if (e.key === 'Enter' && !this.actionTypeDropdown.state.menuIsOpen) {
                                    this.addInput();
                                }
                            }}
                            placeholder="Add"
                            options={actionTypes}
                            value={this.state.selectedActionType}
                            onChange={this.handleActionTypeChange}
                            styles={selectStyle()}
                            ref={ref => this.actionTypeDropdown = ref}
                        />
                    </div>
                }

            </div>
        );
    }
}

//react-select default style makes the element too tall and the font too large
const selectStyle = () => {
    const fontSize = '0.8em';
    return {
        container: styles => ({
            ...styles,
            minWidth: '150px'
        }),
        control: styles => ({
            ...styles,
            minHeight: '0px',
            padding: '0.12em 0.6em !important',
        }),
        valueContainer: styles => ({
            ...styles,
            padding: 0,
        }),
        input: styles => ({
            ...styles,
            fontSize: fontSize,
            lineHeight: 'normal',
        }),
        singleValue: styles => ({
            ...styles,
            fontSize: fontSize,
        }),
        placeholder: styles => ({
            ...styles,
            fontSize: fontSize
        }),
        option: styles => ({
            ...styles,
            fontSize: fontSize
        }),
        dropdownIndicator: styles => ({
            ...styles,
            padding: '0px 8px'
        }),
        menu: provided => ({
            ...provided,
            whiteSpace: 'nowrap',
            width: 'auto'
        })
    };
}

export default InputType;